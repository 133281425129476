body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-table-wrapper,
.ant-menu-horizontal {
  max-width: 900px;
  margin: 0 auto;
}

.ant-table-title {
  padding: 2px 15px;
}

.ant-badge, .ant-menu-item  {
  color: lightgray !important;
}